import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

declare let dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {

  constructor() { }

  GTMDataLayerPush(event:string, result:string, message:string, interactField:string, sif: any, leadImportID:any) {
    try {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push(this.getDataLayerObject(event, result, message, interactField, sif, leadImportID));
        console.log("Datalayer updated values.");
        console.log("event: " + event, " result: " + result, " message: " + message, " interact field: " + interactField);
      }
    } catch (e) {
      console.log("Datalayer failed to update values");
    }
  }

  getDataLayerObject(event:string, result:string, message:string, interactField:string, sif: any, leadImportID:any) {
    const aos = sif.areaOfStudy;
    const degree = sif.degree;
    const program = sif.program;
    const mil = sif.military;
    const zip = sif.zip;
    const campus = sif.campusCode;
    const state = sif.state;
    const bsdegree = sif.bsDegree;
    const source = sif.source;
    const vendor = sif.vendor;
    const lead = leadImportID;
    const startdate = sif.startDate;
    const hashedEmail = this.getHashedEmail(sif.sifEmail);

    return {
      'event': event.toString(),
      'AOS': aos,
      'Degree': degree,
      'Program': program,
      'BSDegree': bsdegree,
      'Military': mil,
      'ZIP': zip,
      'Campus': campus,
      'State': state,
      'Email': hashedEmail,
      'Source': source,
      'Vendor': vendor,
      'LeadImportID': lead,
      'Status': this.getStatusForDataLayer(lead),
      'SIFInteractResult': (result === "") ? '' : result.toString(),
      'SIFInteractField': interactField,
      'InteractErrorMessage': (message === "") ? '' : message.toString(),
      'preferredStartDate': startdate
    }
  }

  getStatusForDataLayer(leadNo: any) {
    let status = "";
    if (leadNo != "") {
      status = leadNo == "0" ? 'Rejected' : 'Accepted';
    }
    return status;
  }

  getHashedEmail(email: string) {
    if (email) {
      const normalizedEmail = email.trim().toLowerCase();
      return CryptoJS.SHA256(normalizedEmail)?.toString() || '';
    }

    return '';
  }
}
