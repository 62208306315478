import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Constants } from 'src/app/config/app.config';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root'
})
export class QueryStringService {

  // if any qsp has to be ignored from the persisted list add it to the below list
  // if any qsp has to be ignored from the persisted list add it to the below list
  blackListParams = [
    'q',
    'aos',
    'zip',
    'country',
    'aoi',
    'branch',
    'skill',
    'rraos',
    'rrdeg',
    'rrpro',
    'programID',
    'areaOfStudy',
    'degreeLevel',
    'id',
    'returnUrl'
  ];

  constructor(@Inject(DOCUMENT) private dom: Document, private commonService: CommonService) {
        this.dom.location.search
  }

  getParameter(name: string, persistCasing: boolean = false, url?: string) {
		if (!url) url = this.dom.location.href || this.dom.URL;
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
    const results = persistCasing ? regex.exec(url) : regex.exec(url.toLowerCase());

		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getFullQspString(urlParams: any): string {
    const existingURLSearchParams = new URLSearchParams(urlParams);
    let existingQueryParams = Object.fromEntries(existingURLSearchParams.entries());
    existingQueryParams = this.removeBlackListedParams(existingQueryParams);
    const sanitizedParams = this.sanitizeParams(existingQueryParams);
    return new URLSearchParams(sanitizedParams).toString();
  }

  appendQuerystringParams(url: string): string {
    const urlSearchParams = this.dom.location.search;

    if (url?.includes(urlSearchParams)) {
      return url;
    }

    const qsp = this.getFullQspString(urlSearchParams);
    const paramLink = url.includes('?') ? '&' : '?';
    return qsp ? `${url}${paramLink}${qsp}` : url;
  }

  /**
   * Removes the black listed keys from the query params object
   * @param queryParams 
   * @returns 
   */
  removeBlackListedParams(queryParams: any) {
    if (typeof queryParams === 'object') {
      this.blackListParams.forEach(item => {
        delete queryParams[item]
      })
    }
    return queryParams;
  }

  /**
   * To sanitize query parameters
   * @param queryParams 
   * @returns 
   */
  private sanitizeParams(queryParams: any) {
    if (Object.keys(queryParams).length === 0)
      return queryParams
    const sanitizedParams: any = {};
    for (const key in queryParams) {
      const sKey = this.sanitize(key);
      const sValue = this.sanitize(queryParams[key])
      if (sKey.length > 0 && sValue.length > 0)
        sanitizedParams[sKey] = sValue;
    }
    return sanitizedParams;
  }

  /**
  * Method to validate whether a string contains harmful content 
  * @param value string to be sanitized
  * @returns 
  */
  private sanitize(value: string): string {
    value = decodeURIComponent(value)
    if (value.match(/( or | and |\$|\[|\]|\r\n|\n|\r|\*|\^|<.*?[a-zA-Z0-9].*\/?>)/ig)) {
      return '';
    }
    return value;
  }
}
